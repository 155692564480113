/* COMPONENTS */
import './components/hamburger.js';
import './components/video.js';
import './components/slider.js';

/* COOKIES / ANALYTICS */
import CookiesEuBanner from 'cookies-eu-banner';

new CookiesEuBanner(function () {
  // Your code to launch when user accept cookies

  var newScript = document.createElement('script');
  newScript.type = 'text/javascript';
  newScript.setAttribute('async', 'true');
  newScript.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=UA-35059794-2');
  document.documentElement.firstChild.appendChild(newScript);

  window.dataLayer = window.dataLayer || [];
  function gtag() {
    dataLayer.push(arguments);
  }
  gtag('js', new Date());

  gtag('config', 'UA-35059794-2', { anonymize_ip: true });
}, true);

(function () {
  let scrollButtons = document.querySelectorAll('.scroll-down');

  scrollButtons.forEach(function (button) {
    button.addEventListener('click', function () {
      let introHeight = document.querySelector('.video-intro').offsetHeight;
      window.scrollTo(0, introHeight);
    });
  });
})();
