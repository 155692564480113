// import Swiper JS
import Swiper, { Pagination, Navigation, HashNavigation, EffectFade } from "swiper";
//Swiper.use([Pagination, Navigation, HashNavigation]);

(function () {
  var mySwiper = new Swiper('.swiper', {
    modules: [Pagination, Navigation, HashNavigation, EffectFade],
    loop: false,
    slidesPerView: 1,
    autoHeight: true,
    simulateTouch: false,
    pagination: {
      el: '.swiper-pagination',
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    hashNavigation: {
      replaceState: true,
      watchState: true
    },
    effect: 'fade',
    fadeEffect: {
      crossFade: true
    },
  });

  mySwiper.on('slideChange', function () {
    window.scrollTo(0, 0)
  });
})();
