(function () {
  let hamburger = document.querySelector('.button-container .hamburger');
  let body = document.querySelector('body');

  hamburger.onclick = function () {
    this.classList.toggle('is-active');
    body.classList.toggle('menu-open');
  };

  let links = document.querySelectorAll('.side-menu li a');

  for (var i = 0; i < links.length; i++) {
    links[i].addEventListener('click', function (e) {
      body.classList.remove('menu-open');
      hamburger.classList.remove('is-active');
    });
  }
})();
