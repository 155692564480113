import Player from '@vimeo/player';

(function () {
  function openVideoOverlay(videoid) {
    var videoOverlay = document.querySelector('.video-overlay');

    let options = {
      id: videoid,
      width: 1024,
      dnt: true
    };

    let player = new Player('embed-container', options);

    videoOverlay.classList.add('active');
    player.play();

    player.on('ended', function (data) {
      videoOverlay.classList.remove('active');
      player.destroy();
    });

    let closeButton = document.querySelector('#close');

    closeButton.addEventListener('click', function () {
      videoOverlay.classList.remove('active');
      player.destroy();
    });
  }

  let videoButtons = document.querySelectorAll('.button');

  videoButtons.forEach(function (button) {
    button.addEventListener('click', function () {
      openVideoOverlay(button.dataset.id);
    });
  });
})();
